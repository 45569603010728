import { FC, ReactElement, useEffect, useState } from 'react';
import FileService from '../../../services/FileService';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { useImageCache } from '../../../contexts/ImageCacheContext';
import { useRandomProfilePicture } from '../../../hooks/useRandomProfilePicture';

type UploadedImageProps = {
  uniqueId: string; // Used locally to generate consistent random images
  fileId: string;
  className?: string;
  placeholder?: ReactElement;
};

const isLocal = location.host.indexOf('localhost') !== -1;

const UploadedImage: FC<UploadedImageProps> = (props) => {
  const { fileId, className, placeholder, uniqueId } = props;
  const { getImage, setImage } = useImageCache();
  const [image, setLocalImage] = useState<string | null>(null);
  const randomImageUrl = useRandomProfilePicture(uniqueId);

  useEffect(() => {
    setLocalImage(null);
    if (!fileId && isLocal && uniqueId) {
      setLocalImage(randomImageUrl);
    } else if (fileId) {
      const cachedImage = getImage(fileId);
      if (cachedImage) {
        setLocalImage(cachedImage);
        return;
      }

      FileService.getFile(fileId).then((fileBlob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setLocalImage(base64String);
          setImage(fileId, base64String);
        };
        reader.readAsDataURL(fileBlob);
      });
    }
  }, [fileId, getImage, randomImageUrl, setImage, uniqueId]);

  return image ? <img src={image} className={className} {...dataAttributeProps(props)} alt="" /> : placeholder ? placeholder : <></>;
};

export default UploadedImage;
