import { useCallback, useMemo } from 'react';

function hash(str: string) {
  let hash = 5381,
    i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}
const maxHashValue = 4294967295;

function hashToNumberBetween(str: string, begin = 0, end = 100) {
  const hashValue = hash(str);
  const range = end - begin;
  const percent = hashValue / maxHashValue;
  return Math.floor(begin + range * percent);
}

export const useGetRandomProfilePicture = () => {
  return useCallback((uniqueId: string) => {
    uniqueId ??= '00000000-0000-0000-0000-000000000000';

    const gender = parseInt(uniqueId[0], 16) < 8 ? 'men' : 'women';
    const uniqueIdHash = hashToNumberBetween(uniqueId, 0, 100);
    const randomImageUrl = `https://randomuser.me/api/portraits/${gender}/${uniqueIdHash}.jpg`;

    return randomImageUrl;
  }, []);
};

export const useRandomProfilePicture = (uniqueId: string) => {
  const getPicture = useGetRandomProfilePicture();
  const url = useMemo(() => getPicture(uniqueId), [getPicture, uniqueId]);

  return url;
};
